import React, { useEffect } from "react";
import homeimg from "../../../../assets/taha-assets/13.png";
import homeimgph2 from "../../../../assets/taha-assets/formula.png";
import homeimgph3 from "../../../../assets/taha-assets/physics.png";
import homeimgph4 from "../../../../assets/taha-assets/science.png";
import homeimgph5 from "../../../../assets/taha-assets/online-learning.png";
import homeimg3 from "../../../../assets/taha-assets/3.png";
// import homeimg4 from "../../../../assets/taha-assets/10.png";
import homeimg5 from "../../../../assets/taha-assets/logo.png";
import homeimg6 from "../../../../assets/taha-assets/11.png";
// import homeimg7 from "../../../../assets/taha-assets/15.png";
import "../header/header.css";
import AOS from "aos";
import $ from "jquery";
import "aos/dist/aos.css";
export const Header = () => {
  AOS.init({
    offset: 200,
    duration: 600,
    easing: "ease-in-sine",
    delay: 100,
    once: false,
    mirror: true,
  });
  useEffect(() => {
    if (window.scrollY < 1) {
      $(".scroll").css("display", "none");
    }
    window.addEventListener("scroll", function () {
      if (window.scrollY < 1) {
        $(".scroll").css("display", "none");
        $(".scroll").css("display", "none");
      } else {
        $(".scroll").css("display", "block");
      }
    });
  }, []);
  const scroll = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth", // أو 'auto' للتمرير الفوري
    });
  };
  return (
    <section className="home-content">
      <div className="scroll">
        <button onClick={scroll} class="button">
          <svg class="svgIcon" viewBox="0 0 384 512">
            <path d="M214.6 41.4c-12.5-12.5-32.8-12.5-45.3 0l-160 160c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 141.2V448c0 17.7 14.3 32 32 32s32-14.3 32-32V141.2L329.4 246.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3l-160-160z"></path>
          </svg>
        </button>
      </div>
      <img
        data-aos="fade-left"
        data-aos-duration="1600"
        className="tt"
        src=""
        alt=""
        srcSet={homeimg6}
      />
      <img
        data-aos="fade-left"
        data-aos-duration="1600"
        className="hh"
        src=""
        alt=""
        // srcSet={homeimg7}
      />
      <section className="home">
        <div
          data-aos="fade-right"
          data-aos-duration="1600"
          className="home-left"
        >
          <img className="img1" srcSet={homeimg} alt="" />
          <img
        data-aos="fade-left"
        data-aos-duration="1600"
        className="t"
        src=""
        alt=""
        srcSet={homeimgph2}
      />
      <img
        data-aos="fade-up"
        data-aos-duration="1800"
        className="h"
        src=""
        alt=""
        srcSet={homeimg5}
      />
      <img
        data-aos="fade-down"
        data-aos-duration="1400"
        className="s"
        src=""
        alt=""
        srcSet={homeimgph4}
      />
      <img
        data-aos="fade-left"
        data-aos-duration="1400"
        className="l"
        src=""
        alt=""
        srcSet={homeimgph5}
      />
        </div>
        <div
          data-aos="fade-left"
          data-aos-duration="1000"
          className="home-right"
        >
          <div className="text text-white">
            <h1>مرحباً بكم فى منصة </h1>
            <h1>
              المهندس فى <span>الفيزياء</span>
            </h1>
            <h1>مع المهندس</h1>
            <h1>محمد صلاح</h1>
          </div>
        </div>
      </section>
      <div data-aos="fade-down" data-aos-duration="1000" className="home-down">
        <img src="" alt="" srcSet={homeimg3} />
      </div>
    </section>
  );
};
