import React, { useContext, useEffect, useState } from 'react'
import axios from 'axios'
import config from '../config'
import { Link, Navigate, useParams } from 'react-router-dom'
import { auth } from '../Context/AuthStore'
import Placeholder from '../Components/Used/Placeholder'

export default function SubscriptionGaurd(props) {

    const { course_id } = useParams();
    const { userAuth } = useContext(auth);
    const [authrization, setAuthrization] = useState(false)
    const [loading, setLoading] = useState(true)

    const checkSubscription = () => {
        axios.get(`${config.basURL}subscription/access-course/${course_id}`, {
            headers: {
                'Authorization': config.ApiKey,
                'Auth': `Bearer ${userAuth}`
            }
        }).then((res) => {
            setAuthrization(res.data.status)
            setLoading(false)
            console.log(res.data)
        }).catch((err) => {
            console.log(err)
        })
    }

    useEffect(() => {
        checkSubscription();
        return () => false;
    }, [])

    return <>

        {
            loading ? <div className="subscription-gourd">
                <Placeholder />
            </div> : (authrization ? props.children : <div className="subscription-gourd">
                <h5>
                    انت غير مشترك في هذا الكورس
                </h5>
                <Link to={'/courses'}>
                    الذهاب الي الكورسات
                </Link>
            </div>) 
        }
    </>
}
