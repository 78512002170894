import React, { useEffect } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import Header from '../Components/Header';
import Footer from '../Components/Footer';
import '../App.css';
import Swal from 'sweetalert2';

export default function Layout() {
  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  } , [location.pathname])
  
  const showSwal = (message , icon, redirect = '') => {
    Swal.fire({
      text:message,
      didClose: () => redirect && navigate(redirect),
      icon: icon
    })
  }
  return <>
    <Header></Header>
    <Outlet context={{showSwal}}></Outlet>
    <Footer></Footer>
  </>
}
