import React, { useEffect } from "react";
import "../about/about.css";
import { IoIosCheckmarkCircle } from "react-icons/io";
import AOS from "aos";
import im1 from "../../../../assets/taha-assets/6.png";
import im2 from "../../../../assets/taha-assets/7.png";
import im3 from "../../../../assets/taha-assets/13.png";
import { Link } from "react-router-dom";
export const About = () => {
  useEffect(() => {
    AOS.init({
      offset: 200,
      duration: 600,
      easing: "ease-in-sine",
      delay: 100,
    });
  });
  return (
    <div className="about">
      <div className="title" data-aos="fade-up" data-aos-duration="1000">
        <svg
          data-aos="fade-up"
          data-aos-duration="1000"
          className="svg-animation"
        >
          <text x="50%" y="50%" dy="0.35em" text-anchor="middle" fill="white">
            المهندس{" "}
          </text>
        </svg>
        <svg
          data-aos="fade-up"
          data-aos-duration="1000"
          className="svg-animation"
        >
          <text x="50%" y="50%" dy="0.35em" text-anchor="middle" fill="white">
            فى{" "}
          </text>
        </svg>
        <svg
          data-aos="fade-up"
          data-aos-duration="1000"
          className="svg-animation"
        >
          <text x="50%" y="50%" dy="0.35em" text-anchor="middle" fill="white">
            الفيزياء{" "}
          </text>
        </svg>
      </div>
      <div className="about-her">
        <h1 data-aos="fade-up" data-aos-duration="1000">
          أقـوي منصة تـعليمية مـتخصصة في مـادة الفزياء
        </h1>
        <p data-aos="fade-up" data-aos-duration="1000">
          بيئة رقمية مصممة لتوفير موارد تعليمية شاملة وفعالة وتعزيز فـهم الطلاب
          في مادة الفيزياء
        </p>
        <div className="hashtag">
          <img
            data-aos="fade-left"
            data-aos-duration="1000"
            src=""
            alt=""
            srcSet={im2}
          />
          <div
            data-aos="fade-up"
            data-aos-duration="1000"
            className="about-img-middle"
          >
            <img className="teacher" src="" alt="" srcSet={im3} />
            <Link className="button" to="/Auth">
              سجل الأن
            </Link>
          </div>
          <img
            data-aos="fade-right"
            data-aos-duration="1000"
            src=""
            alt=""
            srcSet={im1}
          />
        </div>
        <div className="propert">
          <div data-aos="fade-right" data-aos-duration="1000" className="propp">
            تقييم مستوي الطالب شهريا
            <IoIosCheckmarkCircle className="icon" />
          </div>
          <div data-aos="fade-up" data-aos-duration="1000" className="propp">
            مراجعات وامتحانات شاملة
            <IoIosCheckmarkCircle className="icon" />
          </div>
          <div data-aos="fade-left" data-aos-duration="1000" className="propp">
            توفير اكبر قدر من الأسئلة
            <IoIosCheckmarkCircle className="icon" />
          </div>
        </div>
      </div>
    </div>
  );
};
