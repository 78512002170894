import React, { useState } from 'react'
import { Link } from 'react-router-dom';

export default function CourseContentCollapse({data}) {

    const [openCollapse, setOpenCollapse] = useState(false)

    let toggleCollapse = () => {
        let copy = openCollapse
        copy ? copy = false : copy = true ;
        setOpenCollapse(copy)
    }

  return <>
    <div className='course-content-collapse col-lg-12'>
        <div className={`top-bar ${openCollapse ? 'open': ''}`} onClick={toggleCollapse}>
            <p className='p-0 m-0 fw-bold'>
                {data.name}
            </p>
            <div className='icon'>
                <i class={`fa-solid ${openCollapse ? 'fa-chevron-up': 'fa-chevron-down'}`}></i>
            </div>
        </div>
        <div className={`collapsed-data ${openCollapse ? 'open': ''}`}>
            {
                data.unit_lessons.map((lesson) => <p key={lesson.id}>{lesson.name}</p>)
            }
        </div>
    </div>
  </>
}
