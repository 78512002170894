import React, { useContext, useEffect, useRef, useState } from 'react'
import SectionContainer from '../../../Components/Dashboard/Used/SectionContainer'
import DashboardLessonBox from '../../../Components/Dashboard/Lessons/DashboardLessonBox'
import axios from 'axios'
import config from '../../../config'
import { auth } from '../../../Context/AuthStore'
import Placeholder from '../../../Components/Used/Placeholder'
import { useParams } from 'react-router-dom'
import Validator from '../../../Validator'
import Swal from 'sweetalert2'

export default function Lessons() {

    const { userAuth } = useContext(auth)
    const { unit_id } = useParams()

    const controller = useRef(null)

    const [currentLesson, setCurrentLesson] = useState({})
    const [updateData, setUpdateData] = useState({})

    const [lessons, setLessons] = useState(false)
    const [createLessonData, setCreateLessonData] = useState({})

    const [chooseVideo, setchooseVideo] = useState({})

    const [uploadProgress, setuploadProgress] = useState(0)
    const [isLoading, setIsLoading] = useState(false)

    const chooseVideoType = (e) => {
        let data = { ...chooseVideo }
        if (e.target.value == 'vdocipher') {
            data['vdocipher'] = ''
            if (data['cloud_server'] !== undefined) {
                delete data['cloud_server']
            } else if (data['guardxpert'] !== undefined) {
                delete data['guardxpert']
            }
        } else if (e.target.value == 'guardxpert') {
            data['guardxpert'] = ''
            if (data['cloud_server'] !== undefined) {
                delete data['cloud_server']
            } else if (data['vdocipher'] !== undefined) {
                delete data['vdocipher']
            }
        } else if (e.target.value == 'cloud_server') {
            data['cloud_server'] = ''
            if (data['vdocipher'] !== undefined) {
                delete data['vdocipher']
            } else if (data['guardxpert'] !== undefined) {
                delete data['guardxpert']
            }
        } else {
            delete data['vdocipher']
            delete data['cloud_server']
            delete data['guardxpert']
        }
        setchooseVideo(data)
    }


    const updateCreareData = (e) => {
        let data = { ...createLessonData };

        if (e.target.name === 'cloud_server') {
            delete data['other_content_id']
            delete data['content_id']
            delete data['vdocipher']
            data[e.target.name] = e.target.value
        } else if (e.target.name === 'other_content_id' || e.target.name === 'content_id') {
            delete data['cloud_server']
            delete data['vdocipher']
            data[e.target.name] = e.target.value
        } else if (e.target.name === 'vdocipher') {
            delete data['cloud_server']
            delete data['other_content_id']
            delete data['content_id']
            data[e.target.name] = e.target.value
        }else {
            data[e.target.name] = e.target.value
        }

        setCreateLessonData(data)
    }


    const getLessons = () => {
        axios.get(`${config.basURL}dashboard/lesson-overview/${unit_id}`, {
            headers: {
                'Authorization': config.ApiKey,
                'auth': `Bearer ${userAuth}`,
            }
        }).then(res => {
            console.log(res.data)
            setLessons(res.data.unit)
        }).catch(err => console.log(err))
    }

    const createLesson = (e) => {
        e.preventDefault()
        setIsLoading(true)

        let valid = new Validator;
        valid.setKey('name').setValue(createLessonData.name).required();
        createLessonData.vdocipher != undefined && valid.setKey('vdocipher').setValue(createLessonData.vdocipher).required();
        createLessonData.cloud_server != undefined && valid.setKey('cloud_server').setValue(createLessonData.cloud_server).required();
        let disblayError;

        if (valid.haveError) {
            for (let key in valid.getErrors()) {
                disblayError += valid.getErrors()[key]
            }
            Swal.fire({
                text: disblayError,
                icon: 'error'
            })
            return;
        }

        controller.current = new AbortController();

        axios.post(`${config.basURL}dashboard/lesson-overview/${unit_id}`, createLessonData, {
            signal: controller.current.signal,
            headers: {
                'Authorization': config.ApiKey,
                'auth': `Bearer ${userAuth}`,
                'Content-Type': 'multipart/form-data',
            },
            onUploadProgress: (progressEvent) => {
                const { loaded, total } = progressEvent;
                const percent = Math.floor((loaded * 100) / total)
                setuploadProgress(percent)
            }
        }).then(res => {
            setuploadProgress(0)
            setIsLoading(false)
            getLessons()
            Swal.fire({
                text: 'تم الاضافه بنجاح',
                icon: 'success'
            })
        }).catch(err => {
            console.log(err)
            setIsLoading(false)
        })

    }


    useEffect(() => {
       if(userAuth) {
        getLessons()
       }
    }, [userAuth])

    return <>
        <div className="container-fluid dashboard-lessons-page">
            <h1>
                الدروس
            </h1>
            <form className="new-lesson" onSubmit={createLesson}>
                <div className='lesson-name'>
                    <input type="text" name='name' className='lesson-name-input' placeholder='أسم الدرس' onChange={updateCreareData} />
                    <select name="" id="" onChange={chooseVideoType}>
                        <option> اختر نوع الفيديو </option>
                        <option value="vdocipher"> vdocipher </option>
                        <option value="guardxpert"> guardxpert </option>
                        <option value="cloud_server"> ارفع من الجهاز </option>
                    </select>
                    {
                        chooseVideo['vdocipher'] != undefined && <>
                            <input type="text" name='vdocipher' className='lesson-name-input' placeholder='vdocipher ' onChange={updateCreareData} />
                        </>
                    }
                    {
                        chooseVideo['guardxpert'] != undefined && <>
                            <input type="text" name='other_content_id' className='lesson-name-input' placeholder='other_content_id ' onChange={updateCreareData} />
                            <input type="text" placeholder='content_id' className='lesson-name-input' name='content_id' onChange={updateCreareData} />
                        </>
                    }
                    {
                        chooseVideo['cloud_server'] != undefined && <>
                            <input className='lesson-name-input' type="text" placeholder='video link' name="cloud_server" id="" onChange={updateCreareData} />
                        </>
                    }
                    {
                        isLoading && <>
                            <div className='dashboard-upload-header'>
                                <p> جاري رفع الفيديو.... </p>
                                <button onClick={() => controller.current.abort()}>
                                    الغاء
                                </button>
                            </div>
                            <div className='upload-progress'>
                                <div className='text'>{uploadProgress}%</div>
                                <div className="bar">
                                    <div style={{ width: `${uploadProgress}%` }}></div>
                                </div>
                            </div>
                        </>
                    }

                    <textarea name="description" className='lesson-name-input' id="" placeholder='وصف الدرس' onChange={updateCreareData}></textarea>
                    <button className='save-data'>
                        {
                            isLoading ? <i className="fa-solid fa-circle-notch fa-spin"></i> : 'حفظ البيانات'
                        }
                    </button>
                </div>
            </form>
            {
                lessons && <SectionContainer sectionTitle={lessons.name}>
                    {lessons.unit_lessons.length ? lessons.unit_lessons.map(lesson => <DashboardLessonBox key={lesson.id} refresh={getLessons} unitId={unit_id} data={lesson} />) : <p className='text-center'>لا يوجد دروس في هذه الوحده</p>}
                </SectionContainer >
            }
        </div>
    </>
}
