import axios from 'axios';
import React, { useContext, useEffect, useRef, useState } from 'react'
import config from '../../../config';
import { auth } from '../../../Context/AuthStore';
import Swal from 'sweetalert2';
import Validator from '../../../Validator'

export default function UpdateLesson({ data, refresh  , open}) {

    const player = useRef();
    const iframe = useRef();

    const [updateData, setupdateData] = useState({})
    const { userAuth } = useContext(auth)
    const [isUploading, setisUploading] = useState(false)
    const [updateMedia, setupdateMedia] = useState({})
    const [uploadProgress, setuploadProgress] = useState(0)

    const updateLessonData = (e) => {
        let data = { ...updateData }
        data[e.target.name] = e.target.value;
        setupdateData(data)
    }

    const updateLessonMedia = (e) => {
        let data = { ...updateMedia }
        data[e.target.name] = e.target.value;

        setupdateMedia(data)
    }

    const sendTextData = (e) => {
        e.preventDefault()

        let validation = new Validator;

        // check if have updates or not
        if (Object.keys(updateData).length === 0) {
            Swal.fire({
                text: 'لا يوجد اي تعديل ',
                icon: 'warning'
            })
            return;
        } else {
            Object.keys(updateData).map(key => {
                validation.setKey('name').setValue(updateData[key]).required()
            })

            if (validation.haveError) {
                let errors = [];
                validation.getErrors().map(error => {
                    errors.push(validation.getErrors()[error])
                })

                Swal.fire({
                    text: errors.join('\n'),
                    icon: 'error'
                })
                return
            }

            axios.put(`${config.basURL}dashboard/lesson-update/${data.id}`, updateData, {
                headers: {
                    'Authorization': config.ApiKey,
                    'auth': `Bearer ${userAuth}`,
                    'Content-Type': 'multipart/form-data'
                }
            }).then((res) => {
                refresh()
                Swal.fire({
                    text: 'تم التعديل بنجاح',
                    icon: 'success'
                })
            }).catch((err) => {
                console.log(err)
            })

        }

    }

    const sendMediaData = (e) => {
        e.preventDefault()
        isUploading(true)
        let validation = new Validator;

        // check if have updates or not
        if (Object.keys(updateMedia).length === 0) {
            Swal.fire({
                text: 'لا يوجد اي تعديل ',
                icon: 'warning'
            })
            return;
        } else {
            Object.keys(updateMedia).map(key => {
                validation.setKey('name').setValue(updateMedia[key]).required()
            })

            if (validation.haveError) {
                let errors = [];
                validation.getErrors().map(error => {
                    errors.push(validation.getErrors()[error])
                })

                Swal.fire({
                    text: errors.join('\n'),
                    icon: 'error'
                })
                return
            }

            axios.put(`${config.basURL}dashboard/lesson-update/${data.id}`, updateMedia, {
                headers: {
                    'Authorization': config.ApiKey,
                    'auth': `Bearer ${userAuth}`,
                    'Content-Type': 'multipart/form-data'
                },
                onUploadProgress: (progressEvent) => {
                    const { loaded, total } = progressEvent;
                    const percent = Math.floor((loaded * 100) / total)
                    setuploadProgress(percent)
                }
            }).then((res) => {
                isUploading(false)
                refresh()
                Swal.fire({
                    text: 'تم التعديل بنجاح',
                    icon: 'success'
                })
            }).catch((err) => {
                console.log(err)
            })

        }

    }

    useEffect(() => {
        try{
            player.current = window.VdoPlayer.getInstance(iframe.current);
        }catch(err){
            console.log(err)
        }
    }, [])

    return <>
        <div className={`update-lesson w-100 ${open ? 'open' : ''}`}>
            <form className="right" onSubmit={sendTextData}>
                {
                    data.cloud_server && <>
                        <video controls className='rounded'>
                            <source src={data.cloud_server} type='video/mp4' />
                        </video>
                        <div className="group">
                            <label htmlFor="cloud-server"> اختر فيديو من جهازك </label>
                            <input className='lesson-name-input' type="text" name="cloud_server" id="cloud-server" onChange={updateLessonMedia} />
                        </div>
                        {
                            isUploading && <>
                                <p> جاري رفع الفيديو.... </p>
                                <div className='upload-progress'>
                                    <div className='text'>{uploadProgress}%</div>
                                    <div className="bar">
                                        <div style={{ width: `${uploadProgress}%` }}></div>
                                    </div>
                                </div>
                            </>
                        }
                    </>
                }
                {
                    data.video_otp && <>
                        <iframe
                            className='w-100'
                            ref={iframe}
                            src={`https://player.vdocipher.com/v2/?otp=${data.video_otp.otp}&playbackInfo=${data.video_otp.playbackInfo}`}
                            style={{ 'border': 0 }}
                            allow="encrypted-media"
                            allowFullScreen
                            id='frame'
                        ></iframe>
                        <div className="group">
                            <label htmlFor="vdocipher"> vdocipher </label>
                            <input className='lesson-name-input' type="text" name="vdocipher" id="vdocipher" onChange={updateLessonMedia} />
                        </div>
                    </>
                }
                <button>
                    ارفع الفيديو
                </button>
            </form>
            <form className="left" onSubmit={sendTextData}>
                <div className="group">
                    <label htmlFor="lesson-name"> {data.name} </label>
                    <input type="text" name='name' placeholder='اسم الدرس' id='lesson-name' onChange={updateLessonData} />
                </div>
                <div className="group">
                    <label htmlFor="lesson-description"> {data.description} </label>
                    <textarea name='description' placeholder='وصف الدرس' id='lesson-description' onChange={updateLessonData}></textarea>
                    <button>
                        تعديل
                    </button>
                </div>
            </form>
        </div>
    </>
}
