import React, { useContext } from 'react'
import { Link, useOutletContext } from 'react-router-dom'
import config from '../../config'
import axios from 'axios'
import { auth } from '../../Context/AuthStore'

export default function CourseBox({ data }) {

    const { userAuth } = useContext(auth);
    const {showSwal} = useOutletContext();

    const subscripe = () => {

        if (userAuth === null) {
            showSwal('لا يمكن الاشتراك في الكورس بدون تسجيل الدخول', 'error' , '/auth')
        } else {
            axios.post(`${config.basURL}subscription/register-course`, {
                course: data.id
            }, {
                headers: {
                    'Authorization': config.ApiKey,
                    'auth': `Bearer ${userAuth}`,
                }
            }).then((res) => {
                console.log(res.data)
                if(res.data.created){
                    res.data.data.course.free ? 
                    showSwal('تم الاشتراك في الكورس بنجاح', 'success' , '/user-profile/my-courses') : 
                    showSwal('تم الاشتراك في الكورس بنجاح', 'success' , '/user-profile/course-subscreptions')
                }else {
                    showSwal('لقد قمت بالاشتراك في هذا الكورس من قبل', 'info' )
                }
                
            }).catch((err) => {
                console.log(err)
            })
        }
    }

    function getHumanDate(d) {
        const date = new Date(d);
        const options = { weekday: 'long', year: 'numeric', month: 'short', day: 'numeric' };
        return date.toLocaleDateString('ar', options);
    }

    return <>

        <div className='col-lg-3 course-box'>
            <div className='course-image'>
                <img src={`${data.cover}`} className='w-100 h-100' />
                <div className='add-course-icon'>
                    <i className="fa-solid fa-plus"></i>
                </div>
            </div>
            <div className='course-title'>
                <h5 className='title fw-bold'>
                    {data.name}
                </h5>
                <p className='p-0 m-0'>
                    {data.about}
                </p>
            </div>
            <div className='course-time-price'>
                <div className='course-time'>
                    <div className='time'>
                        <i className="fa-regular fa-clock"></i>
                        <p className='m-0 p-0'>
                            {
                                getHumanDate(data.created)
                            }
                        </p>
                    </div>
                </div>
                <div className='price'>
                    {
                        data.free ?  'مجاني' : <><span>{data.price}</span>جـ</>
                    }
                </div>
            </div>
            <div className='go-to-course'>
                <Link to={`/course-preview/${data.id}`} className='enter'>
                    الدخول للكورس
                </Link>
                <button onClick={subscripe} className='enroll'>
                    أشترك الأن
                </button>
            </div>
        </div>
    </>
}
