import React, { useContext, useEffect } from 'react'
import jwtEncode from 'jwt-encode';
import Placeholder from '../../Components/Used/Placeholder';
import axios from 'axios';
import config from '../../config';
import { auth } from '../../Context/AuthStore';
import { useNavigate, useParams } from 'react-router-dom';
import useQueryParams from '../../Hooks/useQueryParams';

export default function VideoSession() {
    let { unit, lesson } = useQueryParams();
    const { course_id } = useParams()
    const secret = 'k34D23GbR2s3f2Jg4JfR2MfS4Dl3KlYwD6YlLzQJmNk='; // Replace with your actual secret key
    const { userAuth } = useContext(auth)
    const navigate = useNavigate()

    function getBrowserName() {
        let userAgent = navigator.userAgent;
        let browserName;
    
        if (userAgent.indexOf("Edg") !== -1) {
            browserName = "Edge";
        } else if (userAgent.match(/chrome|chromium|crios/i)) {
            browserName = "Chrome";
        } else if (userAgent.match(/firefox|fxios/i)) {
            browserName = "Firefox";
        } else if (userAgent.match(/safari/i) && !userAgent.match(/chrome|chromium|crios/i)) {
            browserName = "Safari";
        } else if (userAgent.match(/opr\//i)) {
            browserName = "Opera";
        } else if (userAgent.match(/msie|trident/i)) {
            browserName = "Internet Explorer";
        } else {
            browserName = "Unknown";
        }
    
        return browserName;
    }

    function getOS() {
        let userAgent = navigator.userAgent;
        let platform = navigator.platform;
        let os = "Unknown";
    
        if (platform.indexOf('Win') !== -1) {
            os = "Windows";
        } else if (platform.indexOf('Mac') !== -1) {
            os = "MacOS";
        } else if (platform.indexOf('Linux') !== -1) {
            os = "Linux";
        } else if (/Android/i.test(userAgent)) {
            os = "Android";
        } else if (/iPhone|iPad|iPod/i.test(userAgent)) {
            os = "iOS";
        }
    
        return os;
    }

    const getUserData = () => {
        axios.get(`${config.basURL}subscription/access-lesson/${course_id}/${lesson}`, {
            headers: {
                'Authorization': config.ApiKey,
                'auth': `Bearer ${userAuth}`
            }
        }).then(res => {

            console.log(getBrowserName())

            if(res.data.credentials !== null) {
                navigate(`/my-courses/${course_id}/watch?unit=${unit}&lesson=${lesson}`)
            }else if(res.data.lesson_data.cloud_server) {
                navigate(`/my-courses/${course_id}/watch?unit=${unit}&lesson=${lesson}`)
            }else if(res.data.lesson_data.content_id) {

                let userData = {
                    "name": res.data.student.name,
                    "userName": res.data.student.user.username,
                    "id": res.data.student.id,
                    "mobile": res.data.student.user.username,
                    "clientId": "12",
                    "email": (res.data.student.email || 'notfound'),
                    "contentId": res.data.lesson_data.content_id,
                    'otherContentId': res.data.lesson_data.other_content_id,
                    "pullZone": "mohamed-salah-ph",
                    "vlib": "289252",
                    "platform": getOS(),
                    "ip": "192.168.1.1",
                    "browser": getBrowserName(),
                    "model": 'pop os'
                }

                const token = jwtEncode(userData, secret, { algorithm: 'HS256' });
                sessionStorage.setItem('otherData', token)

                navigate(`/my-courses/${course_id}/watch?unit=${unit}&lesson=${lesson}`)
                // window.location.replace(process.env.PUBLIC_URL + '/videotest.html')
            }

        }).catch(err => {
            console.log(err)
        })
    }

    useEffect(() => {
        getUserData()
    }, [userAuth])

    return <>
        <div className="subscription-gourd">
            <Placeholder />
        </div>
    </>
}
